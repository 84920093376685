import cx from 'classnames';
import { FC, PropsWithChildren } from 'react';
import useIsNavigating from '../../next/useIsNavigating';

const App: FC<PropsWithChildren> = (props) => {
  const isNavigating: boolean = useIsNavigating();

  return (
    <div className={cx('App', isNavigating && 'App--navigating')}>
      {props.children}
    </div>
  );
};

export default App;
