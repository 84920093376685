import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const DELAY_IN_SECONDS: number = 0.25; // If a page loads in under this delay, no interstitial will be shown.

/**
 * A React hook that returns whether the Next.js framework is currently navigating to another page. There is a slight delay applied to prevent fast-loading pages from flickering.
 * @returns True if the Next.js framework is currently navigating. False otherwise.
 */
const useIsNavigating = (): boolean => {
  const router = useRouter();

  const [isNavigating, setNavigating] = useState(false);
  useEffect(() => {
    let timer: number | NodeJS.Timeout = -1;

    const start = () => {
      timer = setTimeout(() => setNavigating(true), DELAY_IN_SECONDS);
    };

    const end = () => {
      if (timer) {
        clearTimeout(timer);
      }
      setNavigating(false);
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', end);
    router.events.on('routeChangeError', end);

    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', end);
      router.events.off('routeChangeError', end);

      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [router.events]);

  return isNavigating;
};

export default useIsNavigating;
