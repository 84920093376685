import { AppContext, AppInitialProps } from 'next/app';
import App from '../library/components/app';
import '../styles/application.scss';
import React from 'react';

type Props = AppContext & AppInitialProps;
interface State {}

export default class AppPage extends React.Component<Props, State> {
  componentDidMount = (): void => {
    if (process.env.NEXT_PUBLIC_FORCE_HTTPS === 'true') {
      if (!window.location.href.startsWith('https')) {
        const redirect: string = window.location.href.replace(
          'http://',
          'https://'
        );
        window.location.href = redirect;
      }
    }
  };

  render = () => {
    return (
      <App>
        <this.props.Component {...this.props.pageProps} />
      </App>
    );
  };
}
